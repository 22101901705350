@use 'common'

.CoverImages
	display: flex
	flex-direction: column
	align-items: center
	margin: 0 2rem

.Image
	margin: 0 1rem
	margin-top: -3rem
	@media (min-width: 768px)
		margin-top: 0

.ImageText
	margin-top: -10rem
