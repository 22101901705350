@use 'common'
@use 'sass:math'

.InstagramSection
	max-width: 1300px
	margin: 0 auto
	padding: 4rem 0

.Title
	font-size: 20px
	line-height: 24px
	margin-left: 0.5rem
	text-align: center
	@media (min-width: 768px)
		font-size: 30px
		line-height: 37px
		margin-left: 1rem
	a
		text-decoration: underline
		&:hover
			text-decoration: none

.Images
	display: flex
	flex-wrap: wrap
	margin: 0 -.5em

.ImageBox
	width: 100%
	@media (min-width: 480px)
		width: math.div(100%, 2)
	@media (min-width: 600px)
		width: math.div(100%, 3)
	@media (min-width: 800px)
		width: math.div(100%, 4)
	@media (min-width: 992px)
		width: math.div(100%, 5)

.Image
	padding: 0.5rem
	@media (min-width: 768px)
		padding: 1rem
