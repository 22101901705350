@use 'common'

.Header
	display: flex
	flex-direction: column
	z-index: 2
	margin-left: 0.2rem
	@media (min-width: 768px)
		margin-left: 2rem

.HeaderBox
	display: flex
	justify-content: space-between

.Title
	+common.title
	z-index: 2
	margin-right: 1.5em

.ButtonBox
	position: absolute
	top: 0
	right: 0
	bottom: 0

.Button
	margin: 0.5rem
	position: sticky
	top: 2rem
	z-index: 2

	@media (min-width: 768px)
		margin: 1rem

.Name
	z-index: 2
.Name, .ButtonName
	+common.subtitle
