@use 'common'
@use 'sass:math'

.Gallery
	display: flex
	flex-wrap: wrap
	max-width: 1300px
	margin: auto
	padding: 4rem 0
	@media (min-width: 768px)
		padding: 4rem 2rem
	@media (min-width: 1024px)
		padding: 4rem 3rem

.ImageContainer
	width: 100%
	max-width: 100%
	flew-grow: 0
	flex-shrink: 0

	&.nth0
		.Image
			@media (min-width: 768px)
				width: 500px

	&.nth1
		width: 100px
		@media (min-width: 1024px)
			width: 130px

	&.nth2
		width: 200px
		@media (min-width: 1024px)
			width: 230px

	&.nth3
		width: 500px
		margin-left: auto

.Image
	margin: 0.5rem
	@media (min-width: 1024px)
		margin: 1rem
