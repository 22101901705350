@use 'common'
@use 'sass:math'

.LeadParagraph
	display: flex
	flex-direction: column
	align-items: center
	max-width: 768px
	margin: auto

.Image
	width: 25%

.Text
	font-size: 25px
	line-height: 30px
	text-align: center
	margin-top: -2.5rem
	z-index: 1
	@media (min-width: 480px)
		margin-top: -7.5rem
	@media (min-width: 768px)
		font-size: 30px
		line-height: 37px
