@use 'common'

@keyframes fade-in-out
	0%
		opacity: 1
	50%
		opacity: .5
	0%
		opacity: 1

.Form
	display: flex
	flex-direction: column-reverse
	margin: 1rem
	margin-bottom: 5rem
	@media (min-width: 768px)
		flex-direction: row
	@media (min-width: 992px)
		margin-left: 70px

.Fieldset
	border: none
	margin: 0
	padding: 0
	transition: all ease-in-out 1s

	&[disabled]
		opacity: .5

.Title
	+common.title
	margin: 1rem
	@media (min-width: 992px)
		margin-left: 70px

.Input
	border: none
	background-color: common.$mainColor
	padding-bottom: 2px
	border-bottom: 1px solid
	opacity: 0.5
	width: 100%
	&:focus
		outline: none

.Input, .Note
	margin: 20px 0
	font-size: 20px
	line-height: 24px

.Message
	font-family: sathu, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif

.SubmitBtn
	+common.subtitle
	display: flex
	margin: auto
	text-decoration: underline
	text-decoration-thickness: 3px
	cursor: pointer
	border: none
	padding-top: 2rem
	-webkit-appearance: none
	background-color: transparent

	.Fieldset[disabled] &,
	&:hover
		text-decoration: none

	.Fieldset[disabled] &
		animation: fade-in-out infinite .5s

.LeftBox
	@media (min-width: 768px)
		width: 50%
		margin: 1rem

.RightBox
	width: 50%
	margin: 0 auto

.Input[type="file"]
	display: none

.Email
	text-decoration: underline
	&:hover
		text-decoration: none
