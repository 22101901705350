@use '../vars'

=subtitle
	font-size: 35px
	line-height: 43px
	margin: 0
	font-weight: 400
	@media (min-width: 768px)
		font-size: 50px
		line-height: 61px
