@use '../vars'

=title
	font-size: 53px
	line-height: 63px
	font-weight: 400
	margin: 0
	@media (min-width: 375px)
		font-size: 65px * .9
		line-height: 85px * .9

	@media (min-width: 768px)
		font-size: 75px * .9
		line-height: 90px * .9

	@media (min-width: 992px)
		font-size: 95px * .9
		line-height: 110px * .9

	@media (min-width: 1024px)
		font-size: 100px * .9
		line-height: 140px * .9

	@media (min-width: 1200px)
		font-size: 120px * .9
		line-height: 150px * .9

	@media (min-width: 1440px)
		font-size: 144px * .9
		line-height: 176px * .9
