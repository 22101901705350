@use 'common'
@use 'sass:math'

.Image
	display: flex
	flex-wrap: wrap
	justify-content: center
	max-width: 768px
	margin: auto
	padding: 4rem 0
