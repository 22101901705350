@use 'common'
@use 'sass:math'


.BackgroundImages
	display: flex
	justify-content: space-around
	position: absolute
	top: 1rem
	width: 100%
	z-index: 1
	left: 0
	right: 0
	max-width: 1100px
	margin: auto
	pointer-events: none

.BackgroundImage
	width: math.div(100%, 2)
	@media (min-width: 768px)
		width: 40%
